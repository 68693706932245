export default function WarningIcon(): JSX.Element {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 13C11.4477 13 11 12.5523 11 12V8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V12C13 12.5523 12.5523 13 12 13Z"
        fill="#E90000"
      />
      <path
        d="M12 14.9976C11.172 14.9976 10.5007 15.6694 10.5007 16.4982C10.5007 17.327 11.172 17.9988 12 17.9988C12.8281 17.9988 13.4993 17.327 13.4993 16.4982C13.4993 15.6694 12.8281 14.9976 12 14.9976Z"
        fill="#E90000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5987 2.5036C13.4453 0.498806 10.5549 0.498796 9.40147 2.50359L1.20452 16.5012C0.053429 18.502 1.49633 21 3.80311 21H20.197C22.5038 21 23.9467 18.502 22.7956 16.5012L14.5987 2.5036ZM11.1339 3.502C11.5183 2.83374 12.4818 2.83373 12.8663 3.502L21.0632 17.4996C21.4469 18.1665 20.9659 18.9992 20.197 18.9992H3.80311C3.03418 18.9992 2.55322 18.1665 2.93692 17.4996L11.1339 3.502Z"
        fill="#E90000"
      />
    </svg>
  )
}
