import { SubscriptionsDetailsInterface } from '@shared/interfaces/channel/subscriptions-details.interface'
import { SubscriptionDetailsEnum } from '@shared/enums/channel/subscription-details.enum'

export const SUBSCRIPTION_CANCEL_ANYTIME = 'Cancel anytime'
export const SUBSCRIPTION_BILLED_PREFIX = 'Billed'
export const SUBSCRIPTION_ACCESS_ALL = 'Unlimited access to all content'

export const SUBSCRIPTION_DETAILS: SubscriptionsDetailsInterface[] = [
  {
    type: SubscriptionDetailsEnum.DESC,
    text: SUBSCRIPTION_ACCESS_ALL,
  },
  {
    type: SubscriptionDetailsEnum.DESC_DURATION,
    text: SUBSCRIPTION_BILLED_PREFIX,
  },
  {
    type: SubscriptionDetailsEnum.DESC,
    text: SUBSCRIPTION_CANCEL_ANYTIME,
  },
]

// Combat Sport subscription wording
export const CSN_SUBSCRIPTION_ID = 'ccf4635d-d616-4153-8b6c-a4463d302dca'
export const CSN_SUBSCRIPTION_DETAILS = [
  {
    type: SubscriptionDetailsEnum.DESC,
    text: 'Unlimited access to exclusive content',
  },
  {
    type: SubscriptionDetailsEnum.DESC,
    text: 'Special discounts on premier PPV only shows',
  },
  {
    type: SubscriptionDetailsEnum.DESC_DURATION,
    text: SUBSCRIPTION_BILLED_PREFIX,
  },
  {
    type: SubscriptionDetailsEnum.DESC,
    text: 'Cancel Anytime',
  },
]
