import { RootState } from '@root/store'
import { FetchStatus } from '@store/_types'
import {
  PassDetails,
  SubscriptionDetails,
} from '@shared/interfaces/users-billing/user-billing-subsciptions.interfaces'

export const selectUserBillingActivePlansStatus = ({
  userBillingActivePlans,
}: RootState): FetchStatus => {
  return userBillingActivePlans.fetchStatus
}

export const selectUserBillingActivePlansErrorMessages = ({
  userBillingActivePlans,
}: RootState): string => {
  return userBillingActivePlans.errorMessages
}

export const selectUserBillingActivePlansPasses = ({
  userBillingActivePlans,
}: RootState): PassDetails[] => {
  return userBillingActivePlans?.data?.passDetails ?? []
}

export const selectUserBillingActivePlansSubscriptions = ({
  userBillingActivePlans,
}: RootState): SubscriptionDetails[] => {
  return userBillingActivePlans?.data?.subscriptionDetails ?? []
}
