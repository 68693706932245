import { vidzingWebConfig } from '@apis/_utilities/environment/backend.microservices.data'

export const getEnvUrlToUse = (): string => {
  const env = process.env.ENVIRONMENT || 'uat'
  const currentUrl = window.location.href

  if (currentUrl.includes('vidzing.tv')) {
    return vidzingWebConfig[env]
  } else {
    return window.location.origin
  }
}
