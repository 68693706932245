// Customer facing error messages
export const HANDLE_RESET_ERROR_MSG = 'Please check your email.'
export const HANDLE_RESET_INVALID_EMAIL_MSG = 'Enter your email then select forgot password'

export const RESET_PASSWORD_LINK_MSG = 'Reset password link sent. Check your email'
export const EMAIL_CONFIRMATION_SENT = 'Email verification link sent. Please, check your email'
export const EMAIL_CONFIRMATION_SENT_ERROR =
  'You have made too many attempts. Please try again later.'

// Data dog error messages
export const FORGOT_PASSWORD_ERROR = 'Forgot password error'
