import useRedirect from '@hooks/use-redirect'
import { UrlPathsEnum } from '@shared/enums/layout/url-paths.enum'
import { vidzingWebConfig } from '@apis/_utilities/environment/backend.microservices.data'
import { getBaseUrl } from '@utils/url/get-base-url'

export default function useRedirectToMyVidzReview(contentId) {
  const { redirectTo } = useRedirect()

  const redirectToVideoReview = () => {
    const path = `${UrlPathsEnum.MY_VIDZ_VIDEO_REVIEW}/${contentId}`
    redirectTo(path)
  }

  const getVideoReviewContinueURL = () => {
    const env = process.env.ENVIRONMENT || 'uat'

    const currentUrl = window.location.href
    // Using preview url when we're testing in preview url
    if (currentUrl.includes('deploy-preview')) {
      const currentPath = getBaseUrl(window.location.href)
      return `${currentPath}${UrlPathsEnum.MY_VIDZ_VIDEO_REVIEW}/${contentId}`
    } else {
      return `${vidzingWebConfig[env]}${UrlPathsEnum.MY_VIDZ_VIDEO_REVIEW}/${contentId}`
    }
  }

  return { redirectToVideoReview, getVideoReviewContinueURL }
}
