import { ReactElement, useState } from 'react'
import ModalV2 from '@ui/modal-v2'
import WarningIcon from '@library/_icons/warning-icon'
import styles from './styles.module.scss'
import { useSelector } from 'react-redux'
import { selectUser } from '@store/user-slice/_selectors'
import {
  EMAIL_CONFIRMATION_SENT,
  EMAIL_CONFIRMATION_SENT_ERROR,
  FORGOT_PASSWORD_ERROR,
} from '@components/layout/custom-channel/constants/recover-password'
import { datadogLogs } from '@datadog/browser-logs'
import Button from '@ui/cta/button'
import useChannelPathnameInQuery from '@hooks/_channel/use-channel-pathname-in-query'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import useDebounce from '@hooks/use-debounce'
import sendVerificationCode from '@apis/users-microservice/send-verification-code'
import { getEnvUrlToUse } from '@utils/url/get-env-url-to-use'
import { FOUR_AND_HALF_SECONDS_MS, THREE_SECONDS } from '@shared/constants/times.constants'

export interface Props {
  hideDialog: () => void
}

export default function ConfirmEmailDialog({ hideDialog }: Props): ReactElement {
  // Channel data
  const chanelPathname = useChannelPathnameInQuery()
  const channelId = useSelector(selectChannelPropByChannelPathname(chanelPathname)('channelId'))

  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false)
  const [msgToShow, setMsgToShow] = useState<string>('')

  const resetSentMsg = () => {
    setShowSuccessMsg(false)
  }
  const debouncedFunction = useDebounce(FOUR_AND_HALF_SECONDS_MS, resetSentMsg)

  const resendLink = async () => {
    const continueUrl = getEnvUrlToUse() + '/' + chanelPathname

    await sendVerificationCode({ email, continueUrl, channelId })
      .then(() => {
        setShowSuccessMsg(true)
        setMsgToShow(EMAIL_CONFIRMATION_SENT)
      })
      .catch((error) => {
        setShowSuccessMsg(true)
        setMsgToShow(error?.response?.data?.message ?? EMAIL_CONFIRMATION_SENT_ERROR)
        datadogLogs.logger.error(EMAIL_CONFIRMATION_SENT_ERROR, error)
      })
      .finally(() => {
        debouncedFunction()
      })
  }

  const { email } = useSelector(selectUser)

  const body = (
    <div className={styles.confirmEmail}>
      <div className={styles.content}>
        <WarningIcon />
        <p>
          Simply click the link we sent to <span>{email}</span> and we'll have you up and running
          again in no time.
        </p>
        <div>
          {!showSuccessMsg ? (
            <Button
              className={styles.resend}
              label={'Resend link'}
              variant={'hyperlink'}
              theme={'playful'}
              onClick={resendLink}
            />
          ) : (
            <p>{msgToShow}</p>
          )}
        </div>
      </div>
    </div>
  )

  const button = (
    <div>
      <Button className={styles.button} label={'Close'} variant={'primary'} onClick={hideDialog} />
    </div>
  )

  return (
    <ModalV2
      title={'Email confirmation required'}
      type={'warning'}
      body={body}
      buttons={button}
      showCloseButton
      closeModal={hideDialog}
    />
  )
}
