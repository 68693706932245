import { Button } from '../../../_buttons'
import { useAppDispatch, useAppSelector as useSel, useAppSelector } from '@root/store'
import { STRIPE_CONFIG } from '@apis/stripe/config'
import { loadStripe } from '@stripe/stripe-js'
import {
  selectContentDataForEventTracking,
  selectContentPricesByContentId,
  selectContentPropByContentId,
  selectOneOnOneMeetingLink,
} from '@store/contents-slice/_selectors'
import { ReactElement, useContext, useEffect, useState } from 'react'
import setMarketingOptInByChannelId from '@apis/channels-microservice/set-marketing-opt-in-by-channel-id'
import { getContentByIdThunk } from '@store/contents-slice/_thunks'
import type { PurchaseEntity } from '@apis/billing-microservice/_entities/_types'
import { isEqualToOne, isEqualToZero, isGreaterThanOne } from '@root/utils/connascence'
import {
  selectChannelPropByChannelPathname,
  selectChannelThemeByChannelPathname,
} from '@store/channel-slice/_selectors'
import axiosRequest from '@apis/axios'
import {
  PurchaseCheckoutResponse,
  RegistrationEntity,
} from '@apis/billing-microservice/_entities/_types'
import { TrackingEventsEnum } from '@shared/enums/tracking-events.enum'
import { trackEvent } from '@utils/event-tracking'
import purchaseContentByContentIdV2 from '@apis/billing-microservice/purchase-content-by-content-id-v2'
import { ContentPrice } from '@apis/contents-microservice/_entities/_types'
import { useCheckoutContext } from '@providers/checkout-provider/_contexts'
import { selectCodeForCouponValidation } from '@store/coupons-slice/_selectors'
import { useSelector } from 'react-redux'
import { selectAppFeatureFlagStatusByName } from '@store/feature-flag-slice/_selectors'
import { datadogLogs } from '@datadog/browser-logs'
import { useRouter } from 'next/router'
import useRedirectToMyVidzReview from '@hooks/_video-review/use-redirect-to-my-viz-review'
import { DispatchNewParamsContext } from '@components/dialog-provider'
import { NO_DIALOG_PARAMS } from '@components/dialog-provider/dialog/_constants'
import { FOUR_SECONDS_MS, TWO_SECONDS } from '@shared/constants/times.constants'
import delay from '@utils/delay'

export type Props = {
  contentId: string
  channelPathname: string
  hideDialog: () => void
}

export function CheckoutButton({
  contentId,
  channelPathname,
  hideDialog,
}: Readonly<Props>): ReactElement {
  const dispatch = useAppDispatch()
  const router = useRouter()
  // Context
  const { flexiPrice, isJoinMailingList } = useCheckoutContext()

  // Provider
  const dialogDispatch = useContext(DispatchNewParamsContext)

  // Local States
  const [loading, setLoading] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(true)

  // Selectors
  const channelId = useAppSelector(selectChannelPropByChannelPathname(channelPathname)('channelId'))
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))
  const contentPrices = useAppSelector(selectContentPricesByContentId(contentId))
  const eventPriceData = useAppSelector(selectContentDataForEventTracking(contentId)) // Get the price data for the facebook event data
  const promotionCode = useAppSelector(selectCodeForCouponValidation)
  const isVideoReview =
    useSel(selectContentPropByContentId(contentId)('contentType')) === 'video-review'

  // Constants
  const contentPricesCount = contentPrices.length
  const stripePromise = loadStripe(STRIPE_CONFIG)

  // Flags
  const isCouponsForViewersActive = useSelector(
    selectAppFeatureFlagStatusByName('flagCouponsForViewers'),
  )

  // Hooks
  const { getVideoReviewContinueURL } = useRedirectToMyVidzReview(contentId)

  useEffect(() => {
    if (isEqualToOne(contentPricesCount)) {
      setDisabled(false)
    } else if (isGreaterThanOne(contentPricesCount)) {
      if (flexiPrice !== undefined) {
        setDisabled(false)
      } else {
        setDisabled(true)
      }
    } else {
      setDisabled(true)
    }
  }, [contentPricesCount, flexiPrice])

  const handleClick = async () => {
    setLoading(true)

    // Trigger facebook initiate checkout event
    trackEvent(TrackingEventsEnum.INITIATE_CHECKOUT, eventPriceData)

    // If Free
    if (isEqualToOne(contentPricesCount) && isEqualToZero(contentPrices[0].price)) {
      try {
        await setMarketingOptInByChannelId(channelId, isJoinMailingList)
        const registration = (await axiosRequest(
          'billing',
          {
            method: 'POST',
            url: `content/register`,
            data: {
              contentId,
              channelId,
            },
          },
          true,
        )) as RegistrationEntity
        if (registration) {
          dispatch(getContentByIdThunk({ contentId, channelId }))
        }
      } catch (error) {
        datadogLogs.logger.error('Error creating checkout session', {
          error,
          contentId,
          channelPathname,
        })
      } finally {
        // Finish loading on the checkout button
        setLoading(false)
      }
    } else {
      try {
        await setMarketingOptInByChannelId(channelId, isJoinMailingList)
        const stripe = await stripePromise

        const continueUrl = isVideoReview ? getVideoReviewContinueURL() : undefined

        // If multi price with free option
        if (isGreaterThanOne(contentPricesCount) && flexiPrice === 0) {
          // We will make a purchase with the free option
          const purchase = (await purchaseContentByContentIdV2({
            contentId,
            channelId,
            price: flexiPrice,
            contentPriceId: contentPrices.find(
              (contentPrice: ContentPrice) => !!contentPrice && contentPrice?.price === 0,
            )?.contentPriceId,
            promotionCode,
            isCouponsForViewersActive,
            continueUrl,
          })) as PurchaseEntity

          if (purchase) {
            await dispatch(getContentByIdThunk({ contentId, channelId }))
          }
        } else {
          // Purchase has no free option

          const checkoutSession = (await purchaseContentByContentIdV2({
            contentId,
            channelId,
            price: flexiPrice,
            contentPriceId: contentPrices.find(
              (contentPrice: ContentPrice) => !!contentPrice && contentPrice?.price === flexiPrice,
            )?.contentPriceId,
            promotionCode,
            isCouponsForViewersActive,
            continueUrl,
          })) as Partial<PurchaseCheckoutResponse>

          // If the promocode gives a discount less than 100% or there is no promocode then make the stripe checkout session
          if (checkoutSession?.sessionId) {
            // Hide dialog and redirect to checkout:
            hideDialog()
            const result = await stripe?.redirectToCheckout({
              sessionId: checkoutSession.sessionId,
            })
          }

          // If Promocode gives the content for free then we need to fetch the content again to update our states:
          // TODO we need a better way to handle this maybe with a global hook which overrides the useCheckoutVideoFlowState?
          if (checkoutSession.status === 'succeeded') {
            if (isVideoReview) {
              await delay(FOUR_SECONDS_MS)
              dialogDispatch(NO_DIALOG_PARAMS)
              router.push(continueUrl)
            } else {
              await dispatch(getContentByIdThunk({ contentId, channelId }))
            }
          }
        }
      } catch (error) {
        datadogLogs.logger.error('Error creating checkout session', {
          error,
          contentId,
          channelPathname,
        })
      } finally {
        // Finish loading on the checkout button
        setLoading(false)
      }
    }
  }

  // Quick fix for free featured content link
  const freeFeaturedContentLink = useAppSelector(selectOneOnOneMeetingLink(contentId))

  const renderButton = () => {
    if (freeFeaturedContentLink) {
      return (
        <Button
          href={freeFeaturedContentLink}
          target={'_blank'}
          isFullWidth={true}
          channelTheme={channelTheme}
        >
          Book now
        </Button>
      )
    }
    return (
      <Button
        isLoading={loading}
        onClick={handleClick}
        isFullWidth={true}
        disabled={disabled}
        channelTheme={channelTheme}
      >
        Checkout
      </Button>
    )
  }

  return renderButton()
}
