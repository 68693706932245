import {
  selectContentPricesByContentId,
  selectContentPropByContentId as selContent,
} from '@store/contents-slice/_selectors'
import { useAppSelector, useAppSelector as useSel } from '@store/index'
import { selectCartContentItem } from '@store/cart-slice/_selector'
import { ReactElement, useEffect } from 'react'
import { SplitPrice } from '@library/split-price/split-price-fee'
import { ContentType } from '@apis/contents-microservice/_entities/_types'
import { selectChannelPropByChannelPathname } from '@store/channel-slice/_selectors'
import { channelFeeDisplayType } from '@apis/billing-microservice/_entities/_types'
import { SplitPriceNoFee } from '@library/split-price/split-price-no-fee'
import { useCalculateFees } from '@library/_flows/_checkout-video-flow/_hooks/use-calculate-fees'
import { useCheckoutContext } from '@providers/checkout-provider/_contexts'

export type Props = {
  contentId: string
  channelPathname: string
}

export function FlexiPrice({ contentId, channelPathname }: Readonly<Props>): ReactElement {
  const cartContentItem = useAppSelector((state) => selectCartContentItem(state, contentId))
  const prices = useSel(selectContentPricesByContentId(contentId))
  const amount = cartContentItem?.price ?? 0
  const priceLabel = cartContentItem?.label

  // Calculate price and fees
  const priceTotals = useCalculateFees(amount, channelPathname)

  const contentType = useSel(selContent(contentId)('contentType')) ?? 'live'
  const channelFeeDisplayType =
    useSel(selectChannelPropByChannelPathname(channelPathname)('channelFeeDisplayType')) ??
    'exclusive'

  // Context
  const { setFlexiPrice } = useCheckoutContext()

  const checkoutTypeMap: Record<ContentType, string> = {
    live: 'Live Stream',
    vod: 'Video',
    ['external-link']: 'Interactive session',
    bundle: 'Bundle',
    audio: 'Audio',
    'video-review': 'Video review',
  }

  useEffect(() => {
    setFlexiPrice(amount)
  }, [amount])

  const contentMap: Record<channelFeeDisplayType, JSX.Element> = {
    exclusive: (
      <SplitPrice
        amount={amount ?? 0}
        vidzingFee={priceTotals.fee}
        total={priceTotals.total}
        currency={prices[0].currency}
        checkoutTitle={priceLabel ?? checkoutTypeMap[contentType]}
      />
    ),
    inclusive: (
      <SplitPriceNoFee
        total={amount ?? 0}
        currency={prices[0].currency}
        checkoutTitle={priceLabel ?? checkoutTypeMap[contentType]}
      />
    ),
  }
  return contentMap[channelFeeDisplayType]
}
