import { ReactElement, ReactNode } from 'react'
import styles from './styles.module.scss'
import { clsx } from 'clsx'
import RenderIf from '@utils/render-if'
import { ModalType } from '@shared/types/modal.type'
import CloseIcon from '@ui/icons/close'
import { motion } from 'framer-motion'
import { useAppProvider } from '@providers/app-provider/_contexts'

export interface Props {
  title: string
  type?: ModalType
  body: ReactNode
  buttons?: ReactNode
  showCloseButton?: boolean
  isFixed?: boolean
  closeModal?: () => void
}
export default function ModalV2({
  title,
  body,
  buttons,
  showCloseButton,
  type = 'info',
  isFixed = true,
  closeModal,
}: Props): ReactElement {
  const { channelTheme } = useAppProvider()

  const cssVariables: Record<string, unknown> = {
    '--mainColour': channelTheme?.buttonColour ?? 'var(--vidzingEarth)',
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={clsx(styles.modal, isFixed ? styles.fixed : styles.absolute)}
      style={cssVariables}
    >
      <motion.div
        initial={{ scale: 0, rotate: '12.5deg' }}
        animate={{ scale: 1, rotate: '0deg' }}
        exit={{ scale: 0, rotate: '0deg' }}
        className={clsx(styles.wrapperContent, styles[type])}
      >
        <span className={styles.header}>{title}</span>
        <div className={styles.content}>{body}</div>
        <div className={styles.buttons}>
          <RenderIf isTrue={!!buttons}>{buttons}</RenderIf>
        </div>
        <RenderIf isTrue={showCloseButton}>
          <div className={styles.close}>
            <button onClick={closeModal}>
              <CloseIcon />
            </button>
          </div>
        </RenderIf>
      </motion.div>
    </motion.div>
  )
}
