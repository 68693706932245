import type {
  CheckoutDialogParams,
  DialogType,
  DialogParams,
  SubscribeDialogParams,
  GeoBlockAlert,
} from './_types'
import { CheckoutDialogContent } from './checkout-dialog-content'
import { Backdrop } from '@library/backdrop'
import { SubscribeDialogContent } from './subscribe-dialog-content'
import { DispatchNewParamsContext } from '../index'
import { ReactElement, useContext, useMemo } from 'react'
import { NO_DIALOG_PARAMS } from './_constants'
import GeoBlockingModal from './geo-blocking-dialog-content'
import { PassDialogContent } from '@components/dialog-provider/dialog/pass-dialog-content'
import { PassDialogParams } from './_types'
import CheckoutProvider from '@providers/checkout-provider/checkout-provider-context'
import { useDispatch, useSelector } from 'react-redux'
import { resetCouponThunk } from '@store/coupons-slice/_thunks'
import { selectUser, selectUserId } from '@store/user-slice/_selectors'
import ConfirmEmailDialog from '@components/dialog-provider/dialog/confirm-email-dialog'
import { isEmptyString } from '@utils/strings'
import useUserCanBuyContent from '@hooks/_user/use-user-can-buy-content'
import { CanBuyContentEnum } from '@shared/enums/can-buy-content.enum'

export type Props = {
  dialogParams: DialogParams
}

export function Dialog({ dialogParams }: Readonly<Props>): ReactElement {
  const dialogDispatch = useContext(DispatchNewParamsContext)
  const dispatch = useDispatch()

  const { userCanBuy } = useUserCanBuyContent()

  const { emailVerified, userId } = useSelector(selectUser)

  const hideDialog = () => {
    dialogDispatch(NO_DIALOG_PARAMS)
    // Whenever the checkout/subscribe/pass dialog is closed, we want to reset the coupon state.
    dispatch(resetCouponThunk())
  }

  const dialogType = useMemo(() => {
    if (
      dialogParams.dialogType !== 'noDialog' &&
      userId &&
      userCanBuy === CanBuyContentEnum.CANT_BUY
    ) {
      return 'confirmEmail'
    } else {
      return dialogParams.dialogType
    }
  }, [dialogParams.dialogType, emailVerified, userId, userCanBuy])

  const dialogMap: Record<DialogType, JSX.Element | undefined> = {
    checkout: (
      <Backdrop hideDialog={hideDialog}>
        <CheckoutDialogContent
          dialogParams={dialogParams as CheckoutDialogParams}
          hideDialog={hideDialog}
        />
      </Backdrop>
    ),
    subscribe: (
      <Backdrop hideDialog={hideDialog}>
        <SubscribeDialogContent
          dialogParams={dialogParams as SubscribeDialogParams}
          hideDialog={hideDialog}
        />
      </Backdrop>
    ),
    geoBlockAlert: (
      <Backdrop hideDialog={hideDialog}>
        <GeoBlockingModal dialogParams={dialogParams as GeoBlockAlert} hideDialog={hideDialog} />
      </Backdrop>
    ),
    pass: (
      <Backdrop hideDialog={hideDialog}>
        <PassDialogContent
          dialogParams={dialogParams as PassDialogParams}
          hideDialog={hideDialog}
        />
      </Backdrop>
    ),
    confirmEmail: <ConfirmEmailDialog hideDialog={hideDialog} />,
    noDialog: undefined,
  }

  return <CheckoutProvider>{dialogMap[dialogType]}</CheckoutProvider>
}
