import useIsUserLogged from '@hooks/_user/use-is-user-logged'
import { useEffect, useMemo, useRef, useState } from 'react'
import { getUserBillingActivePlansThunk } from '@store/user-billing-slice/active-plans/_thunks'
import { getUserBillingPurchasesThunk } from '@store/user-billing-slice/purchases/_thunks'
import { useDispatch, useSelector } from 'react-redux'
import { CanBuyContentEnum } from '@shared/enums/can-buy-content.enum'
import { PurchaseDetails } from '@shared/interfaces/users-billing/user-billing-purchases.interfaces'
import {
  selectUserBillingPurchases,
  selectUserBillingPurchasesStatus,
} from '@store/user-billing-slice/purchases/_selectors'
import { SubscriptionDetails } from '@shared/interfaces/users-billing/user-billing-subsciptions.interfaces'
import {
  selectUserBillingActivePlansStatus,
  selectUserBillingActivePlansSubscriptions,
} from '@store/user-billing-slice/active-plans/_selectors'
import { isEmptyArray } from '@utils/arrays'
import { selectUser } from '@store/user-slice/_selectors'

export default function useUserCanBuyContent() {
  const dispatch = useDispatch()
  const fetchPurchases = useRef(false)

  // State
  const [userCanBuy, setUserCanBuy] = useState<CanBuyContentEnum>(CanBuyContentEnum.NO_USER)
  const [hasFetchedData, setHasFetchedData] = useState<boolean>(false)

  // Selectors
  const purchases: PurchaseDetails[] = useSelector(selectUserBillingPurchases)
  const purchasesFetchStatus = useSelector(selectUserBillingPurchasesStatus)
  const { emailVerified } = useSelector(selectUser)

  const subscriptions: SubscriptionDetails[] = useSelector(
    selectUserBillingActivePlansSubscriptions,
  )
  const subscriptionsFetchStatus = useSelector(selectUserBillingActivePlansStatus)

  // Hooks
  const { isFBLoading, isLogged } = useIsUserLogged()

  // Fetch purchases or subscriptions/plans
  useEffect(() => {
    if (!isFBLoading && isLogged && !fetchPurchases.current) {
      setUserCanBuy(CanBuyContentEnum.LOADING)
      dispatch(getUserBillingActivePlansThunk())
      dispatch(getUserBillingPurchasesThunk())
      fetchPurchases.current = true
    } else {
      setUserCanBuy(CanBuyContentEnum.NO_USER)
    }
  }, [isFBLoading, isLogged])

  useEffect(() => {
    if (fetchPurchases.current) {
      if (purchasesFetchStatus === 'succeeded' && subscriptionsFetchStatus === 'succeeded') {
        setHasFetchedData(true)
      }
    }
  }, [purchasesFetchStatus, subscriptionsFetchStatus])

  useEffect(() => {
    if (hasFetchedData && !emailVerified && !isFBLoading && isLogged) {
      if (!isEmptyArray(subscriptions) || !isEmptyArray(purchases)) {
        setUserCanBuy(CanBuyContentEnum.CAN_BUY)
      } else {
        setUserCanBuy(CanBuyContentEnum.CANT_BUY)
      }
    }
  }, [hasFetchedData, emailVerified, isFBLoading, isLogged])

  useEffect(() => {
    if (emailVerified) {
      setUserCanBuy(CanBuyContentEnum.CAN_BUY)
    }
  }, [emailVerified])

  return { userCanBuy }
}
