import axiosRequest from '@apis/axios'
import { UserSendCode } from '@shared/interfaces/user/user.interface'

export default async function sendVerificationCode({
  email,
  continueUrl,
  channelId,
}: UserSendCode): Promise<UserSendCode> {
  return (await axiosRequest(
    'users',
    {
      method: 'POST',
      url: `/email-verification-link`,
      data: {
        email,
        continueUrl,
        channelId,
      },
    },
    true,
  )) as UserSendCode
}
