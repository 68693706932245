import { AnimatePresence, motion } from 'framer-motion'
import { PositionEnum } from '@shared/enums/layout/position.enum'
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { THREE_SECONDS } from '@shared/constants/times.constants'
import { clsx } from 'clsx'
import CloseIcon from '@ui/icons/close'
import styles from './styles.module.scss'

export interface Props {
  children: ReactNode
  message: string
  messagePosition?: PositionEnum
}

export default function TooltipWrapper({
  children,
  message,
  messagePosition = PositionEnum.TOP,
}: Props): ReactElement {
  // States
  const [active, setActive] = useState<boolean>(false)
  const [localActive, setLocalActive] = useState<boolean>(false)
  const [heightToolTip, setHeightTooltip] = useState<number>(140)

  // Refs
  const wrapperRef = useRef<HTMLDivElement>(null)

  // Triggers
  const toggleTooltipOn = () => setActive(true)
  const toggleOffTooltip = () => {
    setActive(false)
  }

  useEffect(() => {
    const ref = wrapperRef.current
    if (ref !== null) {
      setHeightTooltip(ref.clientHeight)
      ref.addEventListener('mouseover', toggleTooltipOn)
      ref.addEventListener('click', toggleTooltipOn)
      ref.addEventListener('mouseleave', toggleOffTooltip)

      return () => {
        ref.removeEventListener('mouseover', toggleTooltipOn)
        ref.removeEventListener('click', toggleTooltipOn)
        ref.removeEventListener('mouseleave', toggleOffTooltip)
      }
    }
  }, [])

  useEffect(() => {
    if (active && !localActive) {
      setLocalActive(true)
    }
  }, [active, localActive])

  useEffect(() => {
    if (!active && localActive) {
      const delay = setTimeout(() => {
        setLocalActive(false)
      }, THREE_SECONDS)
      return () => clearInterval(delay)
    }
  }, [active, localActive])

  const variants = {
    active: {
      opacity: 1,
      y: -heightToolTip,
      transition: {
        duration: 0.4,
        ease: 'easeOut',
      },
    },
    inactive: {
      opacity: 0,
      y: -40,
      transition: {
        duration: 0.3,
      },
    },
  }

  const motionProps = {
    variants,
    initial: 'inactive',
    animate: 'active',
    exit: 'inactive',
  }

  const cssVariables: Record<string, unknown> = {
    '--tooltipSize': `${heightToolTip}px`,
  }

  return (
    <div ref={wrapperRef} className={styles.tooltip} style={cssVariables}>
      {children}
      <AnimatePresence>
        {localActive && (
          <motion.div {...motionProps} className={clsx(styles.message, styles[messagePosition])}>
            <span dangerouslySetInnerHTML={{ __html: message }} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
