import { RootState } from '@root/store'
import { FetchStatus } from '@store/_types'
import { PurchaseDetails } from '@shared/interfaces/users-billing/user-billing-purchases.interfaces'
import { isEmptyArray } from '@utils/arrays'
import { createSelector } from 'reselect'
import { PurchasesState } from '@store/user-billing-slice/purchases/_interfaces'

export const selectUserBillingPurchasesStatus = ({
  userBillingPurchases,
}: RootState): FetchStatus => {
  return userBillingPurchases.fetchStatus
}

export const selectUserBillingPurchasesErrorMessages = ({
  userBillingPurchases,
}: RootState): string => {
  return userBillingPurchases.errorMessages
}

export const selectUserBillingPurchases = ({
  userBillingPurchases,
}: RootState): PurchaseDetails[] => {
  if (!isEmptyArray(userBillingPurchases?.data?.purchases)) {
    return userBillingPurchases?.data?.purchases.filter(
      (data) => data.content.contentType !== 'video-review',
    )
  }

  return []
}

export const selectUserVideoReviews = createSelector(
  (state: RootState) => state.userBillingPurchases,
  (userBillingPurchases: PurchasesState): PurchaseDetails[] => {
    if (!isEmptyArray(userBillingPurchases?.data?.purchases)) {
      return userBillingPurchases?.data?.purchases.filter(
        (data) => data.content.contentType === 'video-review',
      )
    }
    return []
  },
)

export const selectPurchaseByContentId = (contentId: string) =>
  createSelector(
    (state: RootState) => state.userBillingPurchases,
    (userBillingPurchases: PurchasesState): PurchaseDetails => {
      if (!isEmptyArray(userBillingPurchases?.data?.purchases)) {
        const purchase = userBillingPurchases?.data?.purchases.find(
          (data) => data.content.contentId === contentId,
        )

        return purchase ?? undefined
      }
      return undefined
    },
  )
