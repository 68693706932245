// Times
export const HALF_SECOND_MS = 500
export const ONE_SECOND_MS = 1000
export const ONE_AND_HALF_SECONDS_MS = ONE_SECOND_MS * 1.5
export const TWO_SECONDS = ONE_SECOND_MS * 2
export const TWO_AND_HALF_SECONDS_MS = ONE_SECOND_MS * 2.5
export const THREE_SECONDS = ONE_SECOND_MS * 3
export const THREE_AND_HALF_SECONDS_MS = ONE_SECOND_MS * 3.5
export const FOUR_SECONDS_MS = ONE_SECOND_MS * 4
export const FOUR_AND_HALF_SECONDS_MS = ONE_SECOND_MS * 4.5
export const THIRTY_SECONDS = THREE_SECONDS * 10
export const ONE_MINUTE = THREE_SECONDS * 20
export const ONE_MINUTE_IN_MILLISECONDS = 60000
export const SECONDS_IN_MINS = 60
export const MINS_IN_HOUR = 60
export const HOURS_IN_DAY = 24
export const ONE_HOUR_IN_SECONDS = SECONDS_IN_MINS * MINS_IN_HOUR
export const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS * HOURS_IN_DAY
export const ONE_HUNDRED_MS = 100
//
