import { vidzingWebConfig } from '@apis/_utilities/environment/backend.microservices.data'

/**
 * Generate develop URL
 * @param url
 */
export const buildChannelDevPath = (url: string) => {
  const urlParts = url.split('/').slice(3).join('/')
  return `${vidzingWebConfig['dev']}${urlParts}`
}

/**
 * Validate which URL we need to use as return URL.
 * This case only applies to the local environment.
 * @param url
 */
export const getReturnUrl = (url: string) => {
  if (url.includes(vidzingWebConfig['uat'])) {
    return buildChannelDevPath(url)
  }
  return url
}
