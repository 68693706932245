import styles from './styles.module.scss'
import { useAppSelector } from '@root/store'
import {
  selectChannelThemeByChannelPathname,
  selectSubscriptionDataBySubscriptionId,
} from '@store/channel-slice/_selectors'
import * as subscriptionConstants from '@features/_ui/subscription-terms/_constants'
import { SubscriptionRecurringType } from '@shared/types/subscription.recurring.type'
import { ReactElement, ReactNode, useMemo } from 'react'
import TickIcon from '@features/_ui/icons/tick'
import {
  CSN_SUBSCRIPTION_DETAILS,
  CSN_SUBSCRIPTION_ID,
  SUBSCRIPTION_DETAILS,
} from '@features/_ui/subscription-terms/_constants'
import { SubscriptionDetailsEnum } from '@shared/enums/channel/subscription-details.enum'
import { concatStrings } from '@utils/strings'

interface Props {
  channelPathname: string
  subscriptionId: string
  variant: 'panel' | 'dialog'
}

export default function SubscriptionTerms({
  channelPathname,
  subscriptionId,
  variant,
}: Props): ReactElement {
  const subscription = useAppSelector(
    selectSubscriptionDataBySubscriptionId(channelPathname, subscriptionId),
  )
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))

  /**
   * date-fns format strings for dates. Strings in '' are strings and the letters in the middle
   * are for formatting.
   * e.g. 'on the' do 'of every' passed to the format function = on the 21st of every (do = day, ordinal)
   */
  const recurringFormatMap: Record<SubscriptionRecurringType, string> = {
    day: 'daily',
    week: 'weekly',
    month: 'monthly',
    year: 'yearly',
  }

  const renderIcon = useMemo(() => {
    return variant === 'panel' ? <TickIcon fill={channelTheme?.buttonColour} /> : <span>•</span>
  }, [variant])

  const descriptionToUse =
    subscriptionId === CSN_SUBSCRIPTION_ID ? CSN_SUBSCRIPTION_DETAILS : SUBSCRIPTION_DETAILS

  const renderCombatSport = (
    <>
      {descriptionToUse.map((details) => (
        <li key={details.text}>
          {renderIcon}
          {details.type === SubscriptionDetailsEnum.DESC
            ? details.text
            : concatStrings(
                [details.text, recurringFormatMap[subscription?.interval ?? 'month']],
                ' ',
              )}
        </li>
      ))}
    </>
  )

  return (
    <div className={styles.wrapper}>
      <ul>{renderCombatSport}</ul>
    </div>
  )
}
